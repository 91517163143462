import { createStore } from "vuex";
import configModule from './modules/config';//引入模块;
import rulesModule from './modules/rules';//引入模块
import sameModule from './modules/same';//引入模块
export default createStore({
  modules: {
    config: configModule, //配置文件
    rules: rulesModule, //校验规则
    same: sameModule, //通用方法属性
  },
  state: {
    loadingStatus: false, //全局loading状态
    newMessages: [],//用户的未读信息
    userInfo: {}, //用户信息
    sideBarApp: [], //侧边栏涉及的其他app名字和路径
    isLogin: false, //是否登录过如果没有就先跑登录等几个接口
    batchStyle: 'middle',
    searchFlagAdd: 0,
  },
  mutations: {
    searchFlagAdd(state) {
      state.searchFlagAdd++
    },
    changeNewMessages(state, newMessages) {
      state.newMessages = newMessages;
    },
    changeUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem('page_id', userInfo.page_id)
    },
    changeSideBarApp(state, sideBarApp) {
      state.sideBarApp = sideBarApp;
    },
    changeisLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    changeloadingStatus(state, loadingStatus) {
      state.loadingStatus = loadingStatus;
    },

  },
  actions: {},

});
