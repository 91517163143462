
import { defineComponent } from "vue";
import { Layout, Breadcrumb, Spin } from "ant-design-vue";
import { HomeOutlined, RightOutlined } from "@ant-design/icons-vue";

const { Content } = Layout;
const { Item } = Breadcrumb;

export default defineComponent({
  components: {
    Content,
    Breadcrumb,
    Layout,
    Item,
    HomeOutlined,
    RightOutlined,
    Spin,
  },
  props: {
    breadcrumb: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      minHeight: "90px",
    };
  },
  methods: {
    tokanban() {
      window.open((this as any).$store.state.userInfo.uri_kanban, "_self");
    },
    getAppUrl() {
      if ((this as any).$store.state.sideBarApp.length) {
        return (this as any).$store.state.sideBarApp.filter((el) => {
          return el.name == "MgtMyApp";
        })[0].path;
      } else {
        return "#";
      }
    },
  },
  mounted() {
    this.minHeight = document.body.clientHeight - 120 + "px";
  },
});
